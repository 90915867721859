/* Appointment form styling */
.appointment-form-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 30px;  /* Spacing inside the form container */
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.appointment-form {
    display: flex;
    flex-direction: column;
    align-items: center;  /* Center-aligns form elements */
}

.appointment-form label {
    margin: 15px 0 10px;  /* Increased bottom margin for separation */
    font-weight: bold;
    width: 100%;  /* Full width label */
    text-align: left;  /* Left-aligns label text */
    padding-left: 25px;  /* Indentation for label text */
    box-sizing: border-box;
}

.appointment-form input, .appointment-form textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;  /* Full width within form */
    max-width: 500px;  /* Limit width for consistency */
    box-sizing: border-box;  /* Padding inside width */
    margin-bottom: 15px;  /* Space between each input field */
    text-align: left; /* Align text within the input to the left */
}

.appointment-form button {
    margin-top: 20px;
    padding: 10px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    max-width: 200px;  /* Keeps button centered */
}

.appointment-form button:hover {
    background-color: #0056b3;
}
