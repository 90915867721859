/* CSS Variables for Colors */
:root {
  --primary-color: #007bff;
  --primary-hover: #0056b3;
  --text-color: #333;
  --secondary-color: #666;
  --bg-light: #ffffff;
  --shadow: rgba(0, 0, 0, 0.1);
}

/* General Styling */
.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 20px;
  text-align: center;
}

.home-description {
  font-size: 1.2rem;
  color: var(--secondary-color);
  margin-bottom: 30px;
}

/* Section Styling */
.section {
  margin-top: 40px;
  padding: 20px;
  background-color: var(--bg-light);
  border-radius: 10px;
  box-shadow: 0 4px 12px var(--shadow);
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.section h2 {
  color: var(--primary-color);
  margin-bottom: 10px;
}

.section p {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--text-color);
}

/* Rotating Reviews */
.reviews-section .review {
  font-size: 1.2rem;
  color: var(--text-color);
  margin-top: 20px;
  font-style: italic;
  transition: opacity 0.5s ease-in-out;
}

@keyframes fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* Buttons */
.home-btn {
  background-color: var(--primary-color);
  border: none;
  border-radius: 50px;
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.home-btn:hover {
  background-color: var(--primary-hover);
}

/* Responsive Design */
@media (max-width: 768px) {
  .home-container {
    padding: 30px 10px;
  }

  .section {
    width: 90%;
  }
}
