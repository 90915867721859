/* Global styling and typography */
body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f4f6f9;
  color: #333;
}

/* Color Variables */
:root {
  --primary-color: #007bff;
  --secondary-color: #0056b3;
  --text-color: #333;
  --background-color: #ffffff;
  --shadow-color: rgba(0, 0, 0, 0.1);
}

/* Container styling for central layout */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Fade-in and slide-up animations */
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes slideUp {
  0% { opacity: 0; transform: translateY(20px); }
  100% { opacity: 1; transform: translateY(0); }
}

.fade-in {
  animation: fadeIn 1s ease-out;
}

.slide-up {
  animation: slideUp 0.8s ease-out;
}
