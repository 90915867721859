/* General styling */
.services-container {
    text-align: center;
    padding: 50px 20px;
  }

  .service-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 16px var(--shadow-color);
}
  
  .services-header {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #007bff;
  }

  .services-container {
    padding: 50px 20px;
}

.service-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.service-card {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@media (max-width: 768px) {
    .service-cards {
        flex-direction: column;  /* Stack cards vertically */
        align-items: center;
    }

    .service-card {
        width: 100%;
        max-width: none;  /* Allow full width on mobile */
        margin-bottom: 20px;
    }

    .services-header {
        font-size: 1.8rem;  /* Adjust heading size */
    }
}
  
  /* Service card styling */
  .service-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .services-container {
    text-align: center;
    padding: 100px 20px 50px;  /* Add top padding to avoid overlap */
    max-width: 1200px;
    margin: 0 auto;
}
  
  .service-card {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 300px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }
  
  .service-card h2 {
    color: #333;
    margin-bottom: 15px;
    font-size: 1.5rem;
  }
  
  .service-card p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  /* Button styling */
  .service-btn {
    background-color: #007bff;
    border: none;
    border-radius: 50px;
    color: white;
    padding: 10px 25px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .service-btn:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .service-cards {
      flex-direction: column;
      align-items: center;
    }
  }
  