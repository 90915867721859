/* Contact page styling */
.contact-container {
  text-align: center;
  padding: 50px 20px;
  max-width: 600px;
  margin: 50px auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.contact-header {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #007bff;
}

.contact-description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 20px;
}

.contact-container {
  animation: slideUp 1s ease-out;
}

.contact-container {
  padding: 90px 40px;  /* Top padding to avoid header overlap */
  max-width: 600px;
  margin: 50px auto;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.contact-info {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 15px;
}
