/* Navbar styling */
.navbar {
    background: linear-gradient(135deg, #007bff, #0056b3); /* Gradient background */
    padding: 10px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    border-radius: 0 0 15px 15px;  /* Rounded bottom corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);  /* Subtle shadow for depth */
    transition: background 0.3s ease;  /* Smooth transition effect */
}

.navbar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px 20px;
}

.logo-title {
    display: flex;
    align-items: center;
}

.logo {
    height: 50px;
    margin-right: 10px;
}

.site-title {
    font-size: 1.8rem;
    color: white;
}

/* Desktop Navigation list styling */
.navbar-list {
    list-style-type: none;
    display: flex;
    gap: 20px;
    transition: all 0.3s ease;
}

.navbar-btn {
    color: white;
    padding: 8px 16px;
    font-size: 1rem;
    text-decoration: none;
    font-weight: bold;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.2);  /* Semi-transparent background for buttons */
    transition: background-color 0.3s ease;
}

.navbar-btn:hover {
    background-color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
}

/* Hamburger Menu Icon for smaller screens */
.menu-icon {
    display: none;
    cursor: pointer;
}

.menu-icon .line {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px;
    transition: all 0.3s;
}

/* Responsive Navbar for smaller screens */
@media (max-width: 768px) {
    /* Navbar adjustments for mobile */
    .navbar {
        padding: 8px 10px;
        animation: fadeIn 0.8s ease-out;
    }

    .navbar-content {
        padding: 0 10px;
    }

    .site-title {
        font-size: 1.4rem;  /* Reduced font size on mobile */
    }

    .logo {
        height: 40px;  /* Smaller logo for mobile */
    }

    /* Mobile Navbar dropdown menu style */
    .navbar-list {
        display: none;  /* Hidden by default on mobile */
        flex-direction: column;
        position: absolute;
        top: 60px;
        right: 10px;
        background: linear-gradient(135deg, #007bff, #0056b3);
        padding: 15px;
        border-radius: 10px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        width: 200px;
    }

    /* Display dropdown menu when open */
    .navbar-list.open {
        display: flex;
    }

    .navbar-btn {
        padding: 10px;
        font-size: 1rem;
        width: 100%;
        text-align: center;
    }

    /* Hamburger icon visible only on mobile */
    .menu-icon {
        display: block;
    }

    /* Hamburger icon open/close animation */
    .menu-icon.open .line:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px);
    }
    .menu-icon.open .line:nth-child(2) {
        opacity: 0;
    }
    .menu-icon.open .line:nth-child(3) {
        transform: rotate(-45deg) translate(5px, -5px);
    }
}
